<template>
<transition name="fade">
  <div
    v-if="!compact"
    class="card-template"
    key="card-template"
  >
    <div
      v-if="item.labels && item.labels.length"
      class="card-template__header pl-7 2xl:pl-14 text-base gap-3"
    >
      <div class="label-wrapper gap-4 text-sm 2xl:text-base pb-2 2xl:pb-0">
        <div
          class="label gap-2 h-7 2xl:h-11 px-2.5 2xl:px-4 rounded-lg 2xl:rounded-xl w-28 2xl:w-36"
          v-for="label in item.labels"
          :key="label.title"
          :style="{
            borderColor: label.color
          }"
        >
          <div class="icon">
            <component class="h-4 2xl:h-5" :is="icons.label[label.icon]" />
          </div>
          <span class="name gap-3"><slot name="label" :label="label.title">{{ label.title }}</slot></span>
        </div>
      </div>
      <slot name="label-after"/>
    </div>
    <div class="card-template__body p-4 2xl:pt-6 2xl:pb-7 gap-4 2xl:gap-7 rounded-xl 2xl:rounded-3xl">
      <div class="icon w-7">
        <component :is="icons.activity[item.type]" />
      </div>
      <div class="content break-all">
        <div class="content__top gap-4 text-sm">
          <div class="property property--date">
            {{ item.date }}
          </div>
          <div class="actions gap-2.5 2xl:gap-3">
            <slot name="actions">
              <template v-if="item.data && item.data.actions">
                <template v-for="btn in item.data.actions">
                  <div
                    v-if="buttons[btn]"
                    :key="buttons[btn].id"
                    class="actions__item-wrapper"
                    :title="buttons[btn].title"
                    :name="`${item.id}__action-item__${buttons[btn].id}`"
                    @click.stop.prevent="handleAction(buttons[btn])"
                  >
                    <component
                      :is="buttons[btn].component"
                      v-bind="buttons[btn].componentConfig"
                      @click="buttons[btn].action"
                      class="actions__item"
                      :class="[
                          'actions__item--' + buttons[btn].type,
                          buttons[btn].class,
                        ]"
                      :is-ack="isAcknowledge(item)"
                       :item="item"
                       :style="{ opacity: btn === 'check' && isAcknowledge(item) ? 1 : 0.6}"
                    />
                    <component
                      v-if="buttons[btn].modal"
                      :is="buttons[btn].modal"
                      :type="buttons[btn].type"
                      :to="`${item.id}__action-item__${buttons[btn].id}`"
                    />
                  </div>
                </template>
              </template>
            </slot>
          </div>
        </div>
        <div class="content__main text-xs 2xl:text-sm">
          <slot name="content"/>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="compact-template gap-2 p-4"
    :class="{
      'compact-template--selected': !!selected,
    }"
    key="compact-template"
  >
    <div
      v-if="item.labels && item.labels.length"
      class="compact-template__header text-base gap-3"
    >
      <div class="label-wrapper">
        <div
          class="label gap-2 p-0"
          v-for="label in item.labels"
          :key="label.title"
        >
          <span class="name gap-3"><slot name="label" :label="label.title">{{ label.title }}</slot></span>
        </div>
      </div>
      <slot name="label-after"/>
    </div>
    <div class="compact-template__body gap-7">
      <div class="content">
        <div class="content__main--compact gap-2">
          <slot name="content-compact"/>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>

/* 
  Used Entity Types: 'email', 'call', 'status_change', 'note', 'task'.
  Actions Types: 'reply', 'reply-all', 'forward', 'sent', 'alarm', 'check'.
  Could be configured with an intermediate object
  for the case we have another types from the server
*/
import { buttons } from "@/components/dashboard-components/checklist/utils";
/* Activity Icons */
const emailIcon = () => import("@/assets/icons/dashboard/activity/email.svg");
const callIcon = () => import("@/assets/icons/dashboard/activity/call.svg");
const noteIcon = () => import("@/assets/icons/dashboard/activity/note.svg");
const replaceIcon = () => import("@/assets/icons/dashboard/activity/replace.svg");
const taskIcon = () => import("@/assets/icons/dashboard/activity/task.svg");

/* Label Icons */
const briefcaseIcon = () => import("@/assets/icons/dashboard/label/briefcase.svg");
const graduateIcon = () => import("@/assets/icons/dashboard/label/graduate.svg");
import { EventBus } from "@/main.js";

export default {
  name: 'card-base-template',
  components: {
    // actionModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      buttonLoading: false,
      buttons,
      icons: {
        activity: {
          'email': emailIcon,
          'call': callIcon,
          'status_change': replaceIcon,
          'note': noteIcon,
          'task': taskIcon,
        },
        label: {
          'briefcase': briefcaseIcon,
          'graduate': graduateIcon,
        },
      },
    }
  },
  methods: {
    async handleAction(e) {
    if (this.buttonLoading) {
      return;
    }
    this.buttonLoading = true;
    if (e.action && this.item?.templates?.length) {
      try {
        await this[e.action](e.data);
      } catch (error) {
        console.error(error);
      }
      this.buttonLoading = false;
    } else {
      EventBus.$emit('activityAction', { type: e.type, data: this.item });
      this.buttonLoading = false;
    }
  },
    isAcknowledge(item) {
      return (item.activity_type === 'email' || item.activity_type === 'email_sequence') ? item.is_ack : item.acknowledged;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.card-template {
  // overflow: hidden;
  &__header {
    font-weight: 600;
    margin-bottom: toRem(-22px);
    .label-wrapper {
      display: flex;
      align-items: center;
    }
    .label {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      border-width: 2px;
      border-style: solid;
      // border-radius: 12px;
      // padding: 0 18px;
      min-width: min-content;
      background-color: #fff;
      .name {
        display: inline-flex;
        align-items: center;
      }
      .icon {
        flex-shrink: 0;
      }
    }
  }
  &__body {
    // padding: 25px 15px 28px;
    border: 1px solid #282828;
    // border-radius: 24px;
    display: flex;
    align-items: center;
    .icon {
      flex-shrink: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &__top {
        align-self: flex-end;
        display: inline-flex;
        align-items: center;
        .actions {
          display: inline-flex;
          align-items: center;
          &__item {
            cursor: pointer;
            transition: filter .3s;
            &--check {
              &.disabled {
                fill: #AFAFAF;
                cursor: default;
              }
            }
            &.hover {
              &:hover {
                filter: brightness(1.2);
              }
            }
          }
        }
      }
      &__main {
        &--compact {
          display: none;
        }
      }
    }
  }
}
::v-deep {
  .property {
    &--subject {
      // font-size: 1rem;
      @apply text-sm 2xl:text-base;
      font-weight: 700;
      color: var(--theme-color-main);
      // display: flex;
      align-items: center;
      gap: toRem(10px);
    }
    &--from, &--to {
      // font-size: toRem(12px);
      @apply text-xs;
      font-weight: 700;
    }
    &--from {
      color: #A7A7A7;
    }
    &--to {
      color: #000000;
    }
    &--description {
      margin-top: toRem(9px);
      color: #282828;
      // font-size: toRem(14px);
      @apply text-xs 2xl:text-sm;
      line-height: toRem(24px);
      font-weight: 500;
    }
    &--date {
      color: rgba(0, 0, 0, 0.6);
      font-size: toRem(10px);
      @apply 2xl:text-sm;
      font-weight: 600;
    }
    &--status-change {
      margin-top: toRem(6px);
      // font-size: toRem(14px);
      @apply text-xs 2xl:text-sm;
      font-weight: 500;
      display: flex;
      gap: toRem(40px);
      .status {
        text-decoration: underline;
        font-style: italic;
        color: #0D69D5;
        font-weight: 600;
      }
    }
  }
}
.compact-template {
  background-color: #F2F2F2;
  border-radius: toRem(24px);
  display: flex;
  flex-direction: column;
  opacity: .5;
  cursor: pointer;
  transition: opacity .1s;
  &__header {
    font-weight: 600;
    display: flex;
    align-items: center;
    .label-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
      white-space: nowrap;
    }
    .label {
      text-transform: uppercase;
      display: inline;
      &:not(:last-child) {
        &::after {
          content: ', ';
          // white-space: pre;
        }
      }
      .name {
        display: inline;
      }
    }
  }
  &__body {
    .content {
      &__main {
        &--compact {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  &--selected {
    opacity: 1;
  }
  ::v-deep {
    .property {
      &--subject {
        font-weight: 400;
      }
    }
  }
}

.fade-enter-active {
  transition: opacity .1s linear;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  display: none;
}
.bounceEmailCheck {
  background-color: #F6F6F6;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  width: 378px;
  span {
    margin-left: 10px;
    font-size: 14px;
    color: #FF7777;
  }
  svg {
    width: 20px;
  }
}
.icon_list{
    height: 1.25rem !important;
    width: 1.25rem !important;
}
</style>
